<template>
  <v-col>
    <div v-if="hatRechthochladen == true">
      <Options />
    </div>
    <DokumenteListe />
  </v-col>
</template>

<script setup>
import Options from "./options.vue";
import DokumenteListe from "./Dokumentenliste.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

var hatRechthochladen = ref(true);
onMounted(() => {
  store.state.Rechte.forEach((element) => {
    if ((element = "acHochladen")) hatRechthochladen.value = true;
  });
});
</script>
