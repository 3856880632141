<template>
  <v-col>
    <v-card flat class="ma-2">
      <v-card-title class="HeaderTitle">
        <v-toolbar color="primary" density="compact">
          <v-toolbar-title> Account Daten </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-col class="mt-3">
          <v-row class="hidden-xs">
            <v-text-field
              class="mt-2"
              label="E-Mail"
              v-model="store.state.Benutzerdaten.EMail"
              density="compact"
              variant="outlined"
              readonly
            >
            </v-text-field>

            <v-btn class="ButtonAusrichtung" @click="openChangeEMailDialog()">
              E-Mail ändern
            </v-btn>
          </v-row>

          <v-text-field
            class="mt-2 hidden-sm-and-up"
            label="E-Mail"
            v-model="store.state.Benutzerdaten.EMail"
            density="compact"
            variant="outlined"
            readonly
          >
          </v-text-field>

          <v-row>
            <v-btn
              class="ButtonAusrichtung hidden-sm-and-up"
              block
              align="center"
              justify="center"
              @click="openChangeEMailDialog()"
            >
              E-Mail ändern
            </v-btn>

            <v-btn
              @click="openChangePasswordDialog = true"
              block
              align="center"
              justify="center"
              class="ButtonAusrichtung hidden-sm-and-up"
            >
              Passwort ändern
            </v-btn>
          </v-row>

          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              @click="openChangePasswordDialog = true"
              class="ButtonAusrichtung hidden-xs"
            >
              Passwort ändern
            </v-btn>
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>
  </v-col>

  <v-dialog v-model="showchangeDialog">
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar density="compact" color="primary">
          <v-toolbar-title> Sind sie sicher?</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
         <v-icon class="ma-3" color="red" size="75"> mdi-alert </v-icon>
        <div >
          Wenn sie die jetzt E-mail adresse ändern wollen, können sie solange
          kein Standrohr reservieren bis sie wieder freigestellt wurden. Nach
          einer Überprüfung durch einen Mitarbeiter werden sie dann bald wieder
          freigeschaltet.
          <br />
          <br />
          Sind sie sicher das sie fortfahren wollen?
        </div>
  

        <v-btn class="mt-2 mr-2" @click="sendChangeEMail()">Weiter</v-btn>
        <v-btn class="mt-2" @click="showchangeDialog=false">Abbrechen</v-btn>
        </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showchangeEMail">
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar density="compact" color="primary">
          <v-toolbar-title> E-Mail ändern</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="showchangeEMail = false">mdi-close</v-icon>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        Bitte die Neue E-mail eingeben
        <v-text-field
          v-model="EMail"
          class="mt-2"
          label="Neue E-mail"
          density="compact"
          variant="outlined"
        >
        </v-text-field>
      </v-card-text>

      <v-row class="ma-2">
        <v-btn @click="openChangeDialog()">Ok </v-btn>
        <v-btn class="ml-2" @click="showchangeEMail = false">Abbrechen</v-btn>
      </v-row>
    </v-card>
  </v-dialog>

  <v-dialog v-model="openChangePasswordDialog" persistent>
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar density="compact" color="primary">
          <v-toolbar-title> Passwort ändern</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="openChangePasswordDialog = false">mdi-close</v-icon>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="OldPassword"
          label="Altes Passwort"
          type="password"
          variant="outlined"
          density="compact"
        >
        </v-text-field>
        <v-text-field
          v-model="NewPassword"
          label="Neues Passwort"
          type="password"
          variant="outlined"
          density="compact"
        >
        </v-text-field>

        <v-text-field
          v-model="NewPasswordAccept"
          label="Neues Passwort"
          type="password"
          variant="outlined"
          density="compact"
        >
        </v-text-field>
      </v-card-text>
      <v-row class="ma-2">
        <v-btn @click="ChangePassword()">Ok</v-btn>
        <v-btn class="ml-2" @click="openChangePasswordDialog = false"
          >Abbrechen</v-btn
        >
      </v-row>
    </v-card>
  </v-dialog>

  <v-dialog class="ErfolgDialog" v-model="showErfolgDialog">
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar density="compact" color="primary">
          <v-toolbar-title v-if="Erfolgsart == 'changePassword'"
            >Password Erfogreich geändert</v-toolbar-title
          >
          <v-toolbar-title v-if="Erfolgsart == 'changeBenutzer'"
            >Benutzer Erfolgreich geändert</v-toolbar-title
          >
          <v-toolbar-title v-if="Erfolgsart == 'changeEMail'"
            >E-Mail Erfolgreich geändert</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-col>
          <v-row class="ma-2" align="center" justify="center">
            <v-icon color="green" size="100"> mdi-check-circle </v-icon>
          </v-row>
          <v-row
            v-if="Erfolgsart == 'changePassword'"
            align="center"
            justify="center"
            class="ma-2"
          >
            "Passwort erfolgreich geändert"
          </v-row>

          <v-row
             v-if="Erfolgsart == 'changeEMail'"
            align="center"
            justify="center"
            class="ma-2"
          >
            "E-Mail erfolgreich geändert"
          </v-row>
        </v-col>
      </v-card-text>

      <v-row class="ma-2" align="center" justify="center">
        <v-btn @click="showErfolgDialog = false">ok </v-btn>
      </v-row>
    </v-card>
  </v-dialog>

  <v-dialog v-model="Fehler">
    <Errors />
  </v-dialog>
</template>

<script setup>
import Errors from "@/components/main/Errors.vue";
import dbhelper from "@/plugins/dbHelper";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const store = useStore();

var loading = ref(false);
var Fehler = ref(false);

var showchangeEMail = ref(false);
var showchangeDialog = ref(false);
var showErfolgDialog = ref(false);
var openChangePasswordDialog = ref(false);
var openChangeBenutzerDialog = ref(false);
var AltestPasswortFalsch = ref(false);

var Erfolgsart = ref("");

var OldPassword = ref("");
var NewPassword = ref("");
var NewPasswordAccept = ref("");

var Benutzer = ref("");
var EMail = ref("");

var DialogArt = ref("");

function openChangeEMailDialog() {
  DialogArt = "ChangeEMail";
  showchangeEMail.value = true;
}

function openChangeDialog() {
  showchangeDialog.value = true;
}

async function sendChangeEMail() {
  var url = "ChangeEMail";

  var response = await dbhelper.ChangeEMail(url, EMail.value);

  if (
    response.data.fehlertext == "abgemeldet" &&
    response.data.erfolgJN == "N"
  ) {
    store.state.Login = false;
    router.push({ path: "/reservierung/default" });
  }

  if (response.data.erfolgJN == "J") {
    Erfolgsart.value = "changeEMail";
    showErfolgDialog.value = true;
    store.state.Benutzerdaten.EMail=EMail.value
  } else {
    store.state.FehlerCode = 202;
    Fehler.value = true;
  }

  loading.value = false;

  showchangeDialog.value = false;
  showchangeEMail.value = false;
}

async function ChangePassword() {
  var url = "ChangePassword";

  var response = await dbhelper.ChangePassword(
    url,
    OldPassword.value,
    NewPassword.value
  );
  console.log(response);
  if (
    response.data.erfolgJN == "J" &&
    response.data.fehlertext != "FalschesPassword"
  ) {
    if (response.data.fehlertext == "abgemeldet") {
      store.state.Login = false;
      router.push({ path: "/reservierung/default" });
    } else {
      Erfolgsart.value = "changePassword";
      showErfolgDialog.value = true;
    }
  } else {
    store.state.FehlerCode = 202;
    store.state.FehlerText = response.data.fehlertext;
    if (store.state.FehlerText == "FalschesPassword") {
      store.state.FehlerText =
        "Das Alte Passwort war nicht richtig bitte erneut probieren ";
      AltestPasswortFalsch.value = true;
    }
    Fehler.value = true;
  }

  openChangePasswordDialog.value = false;
}

function closechangeDialog() {
  showchangeDialog.value = false;
  showchangeEMail.value = false;
}
</script>

<style scoped>
.ButtonAusrichtung {
  margin: 1em;
  min-width: 250px;
}
.ErfolgDialog {
  width: 25%;
}
</style>