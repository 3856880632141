<template>
	<v-form ref="PflichtfelderAccount">
		<v-card class="mt-2">
			<v-card-title class="HeaderTitle">
				<v-toolbar
					density="compact"
					color="primary">
					<v-toolbar-title> Account Daten </v-toolbar-title>
				</v-toolbar>
			</v-card-title>
			<v-card-text>
				<v-row class="mr-2 ml-2 mt-4 mb-2">
					<v-text-field
						v-model="$store.state.Benutzerdaten.Login"
						label="Benutzername"
						color="primary"
						variant="outlined"
						density="compact"
						:rules="[rules.required]">
					</v-text-field>
				</v-row>

				<v-form ref="EMailVorhanden">
					<v-row class="ma-2">
						<v-text-field
							v-model="$store.state.Benutzerdaten.EMail"
							class="mr-1"
							label="E-Mail"
							color="primary"
							variant="outlined"
							density="compact"
							:rules="[rules.required, rules.EMail]">
						</v-text-field>
					</v-row>
				</v-form>

				<v-row class="ma-2">
					<v-text-field
						v-model="$store.state.Code"
						label="Bestätigungscode"
						color="primary"
						variant="outlined"
						density="compact">
					</v-text-field>
					<v-btn
						class="ma-2"
						@click="CodeGenerieren()">
						{{ CodegesendetText }}
					</v-btn>
				</v-row>

				<v-row
					class="ma-2"
					v-if="CodegesendetMeldung == true">
					<v-alert
						color="green"
						density="compact">
						Code wurde versendet.
					</v-alert>
				</v-row>

				<v-row class="ma-2">
					<v-text-field
						class="mr-1"
						v-model="$store.state.Benutzerdaten.Password"
						label="Passwort"
						color="primary"
						type="password"
						variant="outlined"
						density="compact"
						:rules="[rules.required, rules.laenge, rules.Sonderzeichen]">
					</v-text-field>

					<v-text-field
						v-model="$store.state.Benutzerdaten.PasswordSame"
						label="Passwort bestätigen"
						color="primary"
						type="password"
						variant="outlined"
						density="compact"
						:rules="[rules.required]">
					</v-text-field>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="mt-4">
			<v-card-title class="HeaderTitle">
				<v-toolbar
					density="compact"
					color="primary"
					><v-toolbar-title> Persönliche Daten </v-toolbar-title>
				</v-toolbar>
			</v-card-title>

			<v-card-text>
				<v-row class="mr-2 ml-2 mt-4 mb-2">
					<v-combobox
						label="Erstellt für"
						color="primary"
						v-model="FuerPerson"
						:items="FuerPersonArray"
						variant="outlined"
						density="compact">
					</v-combobox>
				</v-row>

				<v-row
					class="ma-2"
					v-if="FuerPerson == 'Firma'">
					<v-text-field
						v-model="$store.state.Benutzerdaten.Firma"
						label="Firma"
						color="primary"
						variant="outlined"
						density="compact"
						:rules="[rules.required]">
					</v-text-field>
				</v-row>

				<div v-if="FuerPerson == 'Privat Person'">
					<v-row class="ma-2">
						<v-combobox
							v-model="$store.state.Benutzerdaten.Anrede"
							:items="AnredeArray"
							label="Anrede"
							color="primary"
							variant="outlined"
							density="compact"
							:rules="[rules.required]">
						</v-combobox>
					</v-row>

					<v-row class="ma-2">
						<v-text-field
							class="mr-2"
							v-model="$store.state.Benutzerdaten.Vorname"
							label="Vorname"
							color="primary"
							variant="outlined"
							density="compact"
							:rules="[rules.required]">
						</v-text-field>

						<v-text-field
							v-model="$store.state.Benutzerdaten.Nachname"
							label="Nachname"
							color="primary"
							variant="outlined"
							density="compact"
							:rules="[rules.required]">
						</v-text-field>
					</v-row>

					<v-row class="ma-2">
						<v-text-field
							v-model="$store.state.Benutzerdaten.WeitererVorname"
							label="Weiterer Vorname"
							color="primary"
							variant="outlined"
							density="compact">
						</v-text-field>
					</v-row>
				</div>

				<v-row class="ma-2">
					<v-text-field
						v-model="$store.state.Benutzerdaten.Telefon"
						class="mr-1"
						label="Telefon"
						color="primary"
						variant="outlined"
						density="compact"
						:rules="[rules.required]">
					</v-text-field>

					<v-text-field
						v-model="$store.state.Benutzerdaten.Telefon2"
						class="mr-1"
						label="Telefon2 (Optional)"
						color="primary"
						variant="outlined"
						density="compact">
					</v-text-field>

					<v-text-field
						v-model="$store.state.Benutzerdaten.Mobil"
						label="Mobil (Optional)"
						color="primary"
						variant="outlined"
						density="compact">
					</v-text-field>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="mt-4">
			<v-card-title class="HeaderTitle">
				<v-toolbar
					density="compact"
					color="primary">
					<v-toolbar-title> Adresse </v-toolbar-title>
				</v-toolbar>
			</v-card-title>

			<v-card-text>
				<v-row class="mr-2 ml-2 mt-4 mb-2">
					<v-text-field
						v-model="$store.state.Benutzerdaten.Strasse"
						class="mr-1"
						label="Strasse"
						color="primary"
						variant="outlined"
						density="compact"
						:rules="[rules.required]">
					</v-text-field>

					<v-text-field
						v-model="$store.state.Benutzerdaten.Hausnummer"
						label="Hausnummer"
						color="primary"
						variant="outlined"
						density="compact"
						:rules="[rules.required]">
					</v-text-field>
					<v-text-field
						v-model="$store.state.Benutzerdaten.HausNrErg"
						label="Hausnummer"
						color="primary"
						variant="outlined"
						density="compact"
						:rules="[rules.required]">
					</v-text-field>
				</v-row>

				<v-row class="ma-2">
					<v-text-field
						v-model="$store.state.Benutzerdaten.Plz"
						class="mr-1"
						label="Postleitzahl"
						color="primary"
						variant="outlined"
						density="compact"
						:rules="[rules.required]">
					</v-text-field>

					<v-text-field
						v-model="$store.state.Benutzerdaten.Ort"
						label="Ort"
						color="primary"
						variant="outlined"
						density="compact"
						:rules="[rules.required]">
					</v-text-field
				></v-row>

				<v-row class="ma-2">
					<v-text-field
						v-model="$store.state.Benutzerdaten.Land"
						label="Land"
						color="primary"
						variant="outlined"
						density="compact">
					</v-text-field>
				</v-row>
			</v-card-text>
		</v-card>
	</v-form>

	<v-dialog v-model="Fehler">
		<Errors />
	</v-dialog>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import { useStore } from "vuex"

import Errors from "@/components/main/Errors.vue"
import dbhelper from "@/plugins/dbHelper"

const store = useStore()

defineExpose({
	checkvalidate,
})

var score = computed(() => {})

var Fehler = ref(false)
var CodegesendetMeldung = ref(false)
var CodegesendetText = ref("")
var FuerPerson = ref("")
var FuerPersonArray = ["Firma", "Privat Person"]
var PflichtfelderAccount = ref(null)
var EMailVorhanden = ref(null)
var Zufallswert = ""

function zufall(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min
}

var rules = ref({
	required: (value) => !!value || "Pflichtfeld",
	EMail: (value) => {
		var pattern =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return pattern.test(value) || "Ungültige E-Mail"
	},
	laenge: (value) => (value && value.length > 6) || "Mindestens 6 Zeichen",
	Sonderzeichen: (value) =>
		(value && /[^A-Za-z0-9]/.test(value)) ||
		"Mindestens ein Sonderzeichen muss enthalten sein",
})

onMounted(() => {
	FuerPerson.value = "Firma"
	store.state.Benutzerdaten.Anrede = "Herr"
	CodegesendetText.value = "Code Anfordern"
})

async function CodeGenerieren() {
	let MailVorhanden = await EMailVorhanden.value.validate()
	if (MailVorhanden.valid == true) {
		var url = "SendEMailBestaetigungsCode"
		Zufallswert = zufall(1000, 9999)
		store.state.Zufallswert = Zufallswert
		var response = await dbhelper.CodeRequest(
			url,
			Zufallswert,
			store.state.Benutzerdaten.EMail
		)

		if (response.data.erfolgJN == "J") {
			CodegesendetText.value = "Erneut anfordern"
			CodegesendetMeldung.value = true
		} else {
			store.state.FehlerCode = 202
			store.state.FehlerText = response.data.fehlertext
			Fehler.value = true
		}
	}
}

var AnredeArray = ref(["Herr", "Frau"])

async function checkvalidate() {
	let checkAccountPflichtfelder = await PflichtfelderAccount.value.validate()
	if (checkAccountPflichtfelder.valid == true) return true

	return false
}
</script>
