<template>
  <v-card class="ma-4 rounded-xl" variant="outlined">
    <v-card-title class="HeaderTitle">
      <v-toolbar color="primary" density="compact" flat>
        <v-toolbar-title class="textcolor"> Standrohr </v-toolbar-title>
      </v-toolbar>
    </v-card-title>

    <v-card-text>
      <v-col v-if="loading == false">
        <v-form ref="klasse">
          <v-row class="ma-1">
            <v-combobox
              v-if="Vorhanden('Bauform') == true"
              v-model="Geraeteklasse"
              :items="Geraeteklassen"
              label="Geräteklasse"
              color="primary"
              item-title="Bezeichnung"
              density="compact"
              no-data-text="Keine Daten vorhanden"
              variant="outlined"
              :rules="[rules.required('Geraeteklasse', Geraeteklasse)]"
              @update:modelValue="filterupdate()"
            >
            </v-combobox>
          </v-row>
        </v-form>
        <v-form ref="form">
          <v-row class="ma-1" v-if="Vorhanden('Bauform') == true">
            <v-combobox
              v-model="$store.state.Bauform"
              :items="Geraetetypen"
              :filter-keys="filterkeys()"
              label="Wählen sie den benötigten Standrohrtyp"
              color="primary"
              variant="outlined"
              density="compact"
              no-data-text="Keine Daten vorhanden"
              :rules="[rules.required('Bauform', $store.state.Bauform)]"
            ></v-combobox>
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('Konzessionsgebiet') == true">
            <v-combobox
              v-model="$store.state.Konzessionsgebiet"
              :items="Konzessionsgebie"
              :filter-keys="filterkeys()"
              label=" Konzessionsgebiet festlegen"
              color="primary"
              variant="outlined"
              density="compact"
              no-data-text="Keine Daten vorhanden"
              :rules="[rules.required('Konzessionsgebiet', $store.state.Konzessionsgebiet)]"
            ></v-combobox>
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('Einsatzstelle') == true">
            <v-text-field
              v-model="$store.state.Einsatzstelle"
              label="Einsatzstelle"
              color="primary"
              variant="outlined"
              density="compact"
              :rules="[
                rules.required('Einsatzstelle', $store.state.Einsatzstelle),
              ]"
            >
            </v-text-field>
          </v-row>

          <v-row class="ma-1">
            <v-text-field
              v-if="Vorhanden('VoraussichtlicheDauer') == true"
              v-model="$store.state.VoraussichtlicheDauer"
              label="Voraussichtliche Dauer"
              color="primary"
              variant="outlined"
              density="compact"
              :rules="[
                rules.required(
                  'VoraussichtlicheDauer',
                  $store.state.VoraussichtlicheDauer
                ),
              ]"
            >
            </v-text-field>
            <v-spacer
              v-if="
                Vorhanden('Langzeitmiete') == true ||
                Vorhanden('VoraussichtlicheDauer') == true
              "
            ></v-spacer>
            <v-checkbox
              v-if="Vorhanden('Langzeitmiete') == true"
              v-model="$store.state.Langzeitmiete"
              color="primary"
              label="Langzeitmiete"
              density="compact"
              :rules="[
                rules.required('Langzeitmiete', $store.state.Langzeitmiete),
              ]"
            ></v-checkbox>
          </v-row>

          <v-row
            v-if="
              store.state.Bankenmodul == true &&
              Vorhanden('Zahlungsart') == true
            "
            class="ma-1"
          >
            <v-select
              v-model="$store.state.Zahlungsart"
              :items="store.state.BezahlItems"
              label="Zahlungsart"
              color="primary"
              variant="outlined"
              density="compact"
              :rules="[rules.required('Zahlungsart', $store.state.Zahlungsart)]"
            ></v-select>
          </v-row>

          <v-card flat class="ma-1" color="background">
            <v-card-title> Ausgabe an (falls bekannt) </v-card-title>
            <v-card-text>
              <v-row class="ma-1">
                <v-text-field
                  v-if="Vorhanden('AusgabeName') == true"
                  style="margin-right: 0.5em"
                  v-model="$store.state.AusgabeName"
                  label="Name"
                  color="primary"
                  variant="outlined"
                  density="compact"
                  :rules="[
                    rules.required('AusgabeName', $store.state.AusgabeName),
                  ]"
                >
                </v-text-field>

                <v-text-field
                  v-if="Vorhanden('AusgabeVorname') == true"
                  v-model="$store.state.AusgabeVorname"
                  label="Vorname"
                  color="primary"
                  variant="outlined"
                  density="compact"
                  :rules="[
                    rules.required(
                      'AusgabeVorname',
                      $store.state.AusgabeVorname
                    ),
                  ]"
                >
                </v-text-field>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
      <v-row class="ma-2" v-if="loading == true">
        <Loadingscreen />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Loadingscreen from "@/components/main/loadingscreen.vue";
import dbhelper from "@/plugins/dbHelper.js";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

defineExpose({
  checkvalidate,
  resetFelder,
});

//Variablen
const store = useStore();

var form = ref(null);
var klasse = ref(null);

var loading = ref(false);

var Konzessionsgebie=ref([
  "Mönchengladbach",
  "Viersen",
  "Tönisvorst",
  "Grevenbroich",
  "Ohne"
])

var rules = ref({
  required(Feld, value) {
    var istPflichtfeld = false;
    store.state.VertragsPflichtfelder.forEach((element) => {
      if (element == Feld) istPflichtfeld = true;
    });

    if (istPflichtfeld == true) {
      return !!value || "Pflichtfeld";
    } else return true;
  },

  EMail: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Ungültige E-Mail";
  },
});

var Geraeteklassen = ref([]);
var Geraeteklasse = ref("");
var Geraetetypen = ref([]);
var GeraetetypenDB = [];

//functions
onMounted(async () => {
  loading.value = true;
  var Geraeteklasseurl = "GETGeraeteklassen";
  var response = await dbhelper.dbload(Geraeteklasseurl);
  var Geraeteklassendb = JSON.parse(response.DataArray);

  Geraeteklassendb.forEach((element) => {
    if (
      element.Bezeichnung != "Systemtrenner" &&
      element.Bezeichnung != "Zähler"
    )
      Geraeteklassen.value.push(element);
  });

  var Geraetetypenurl = "GETGeraetetypen";

  response = await dbhelper.dbload(Geraetetypenurl);
  if (response.result.erfolgJN == "J") {
    GeraetetypenDB.value = JSON.parse(response.DataArray);
  } else {
    store.state.FehlerCode = 202;
    store.state.FehlerText = response.result.fehlertext;
    Fehler.value = true;
  }
  loading.value = false;
});

function filterupdate() {
  Geraetetypen.value = [];
  if (Geraeteklasse.value != null) {
    GeraetetypenDB.value.forEach((element) => {
      if (
        element.GeraeteklasseID == Geraeteklasse.value.GeraeteklasseID &&
        element.AnzahlImLager > 0
      ) {
        Geraetetypen.value.push(element.Bezeichnung);
      }
      //
    });
    store.state.Bauform = "";
  }
}

function Vorhanden(Feld) {
  var FeldNichtvorhandewn = false;
  store.state.FelderNichtAnzeigen.forEach((element) => {
    if (element == Feld) FeldNichtvorhandewn = true;
  });
  if (FeldNichtvorhandewn == true) return false;
  else return true;
}
function filterkeys() {
  if (Geraeteklasse != null) return Geraeteklasse.Bezeichnung;
}
async function checkvalidate() {
  var result = false;
  let test = await form.value.validate();
  if (test.valid == true) result = true;
  else result = false;

  let test2 = await klasse.value.validate();
  if (test2.valid == true && result == true) result = true;
  else result = false;

  return result;
}

function resetFelder() {
  Geraetetypen.value = [];
  klasse.value.reset();
  form.value.reset();
}
</script>