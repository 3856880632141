// Composables
import { createRouter, createWebHistory } from 'vue-router'
import MainPage from "@/components/MainPage.vue"

import store from "@/Store/index"
import dbhelper from '@/plugins/dbHelper'

const routes = [
  {
    path: '/reservierung/default',
    component: MainPage,
    beforeEnter: async (to, from) => {

      var url = "LoadSettings";
      var response = await dbhelper.loadSettings(url);

      var Kunde = response.Kunde
      store.state.CompanyName = Kunde;

      store.state.Kunde = Kunde.split(/\s+/).join('');
      store.state.VerwaltungEinschalten = response.UserVerwaltung;
      store.state.ToolbarAnzeigen = response.ToolbarAnzeigen;
      store.state.Bankenmodul = response.Bankenmodul;
      store.state.FelderNichtAnzeigen = response.VertragsFelderNichtAnzeigen;
      store.state.VertragsPflichtfelder = response.VertragsPflichtfelder;

      /*ACHTUNG  Auskomentierren für das builden für den entsprechenden Kunden 
      ansosnten werden die unnötieg Kunden CSS mitkopiert in den static ordner
      */
// Paderborn
     /*  if (store.state.Kunde == 'WasserwerkePaderborn') {

        store.state.AppTitle = "Kundenportal";
        store.state.AppTitleShort = 'Kundenportal'
        document.title = store.state.AppTitle;
        var Logo = await import("@/assets/WWPaderborn/WWP_logo.png");
        store.state.Logo = Logo.default;
        store.state.LogoBreite = "150";
        store.state.LogoHoehe = "24";
        store.state.BezahlItems = [
          "Vorabüberweisung",
          "ohne Kaution",
          "Verrechnungsscheck",
          "SEPA Lastschrift",
          "Mastercard",
          "EC Cash",
        ];


        var AGBpath = await import("@/assets/WWPaderborn/AGB.pdf");
        store.state.AGBpath = AGBpath.default;
        var Datenschutzpath = await import("@/assets/WWPaderborn/Datenschutzerklaerung.pdf")
        store.state.Datenschutzpath = Datenschutzpath.default;
        store.state.CookieBannerEingeschaltet = false
        store.state.DokumentArten = ["Alle","Vollmacht", "Gewerbenachweise/HR-Auszüge","Prüfung: Standrohrrückgabe", "Vertrag", "Fotos", "Prüfung: Werkstattfreigabe", "Rechnungen", "Sonstige Dokumente"]

        import("@/assets/WWPaderborn/WWP_KundenConfig.css");
      } */

      // NEW NETZ
           if (store.state.Kunde = 'NEWNetzGmbH') {
    
            var Logo = await import("@/assets/NEW/NEW_NETZ_logo.svg");
            store.state.AppTitle = "Kundenportal";
            store.state.AppTitleShort = 'Kundenportal'
            document.title = store.state.AppTitle;
            var Logo = await import("@/assets/NEW/NEW_NETZ_Logo.svg");
            store.state.Logo = Logo.default;
            store.state.LogoBreite = "150";
            store.state.LogoHoehe = "24";
            store.state.CookieBannerEingeschaltet = false
            store.state.Konzessionsgebiet="Mönchengladbach"
            store.state.BezahlItems = [
              "Vorabüberweisung",
              "ohne Kaution",
              "Verrechnungsscheck",
              "SEPA Lastschrift",
              "Mastercard",
              "EC Cash",
            ];

            import("@/assets/NEW/NEW_Netz_KundenConfig.css");
          } 


      /*  if (store.state.Kunde == 'SWKampLintfort') {
 
         store.state.AppTitle = "Reservierungsportal";
         store.state.AppTitleShort = 'Reservierung'
         var Logo = await import("@/assets/KampLinfort/KampLinfort_logo.jpg");
         store.state.Logo = Logo.default;
         store.state.LogoBreite = "150";
         store.state.LogoHoehe = "24";
         store.state.Zahlungsart = "Vorabüberweisung"
         store.state.BezahlItems = [
           "Vorabüberweisung"
         ];
         // var AGBpath = await import("@/assets/KampLinfort/KampLinfort/AGB.pdf");
         //  store.state.AGBpath = AGBpath.default;
         //  var Datenschutzpath = ''^
         //  store.state.Datenschutzpath = Datenschutzpath.default;
         import("@/assets/KampLinfort/KampLinfort_KundenConfig.css");
       } */
    }
  },

  {
    path: '/reservierung/ResetPage',
    component: MainPage,
    props: true,
    beforeEnter: async (to, from) => {

      var url = "LoadSettings";
      var response = await dbhelper.loadSettings(url);

      var Kunde = response.Kunde
      store.state.CompanyName = Kunde;

      store.state.Kunde = Kunde.split(/\s+/).join('');
      store.state.VerwaltungEinschalten = response.UserVerwaltung;
      store.state.ToolbarAnzeigen = response.ToolbarAnzeigen;
      store.state.Bankenmodul = response.Bankenmodul;
      store.state.FelderNichtAnzeigen = response.VertragsFelderNichtAnzeigen;
      store.state.VertragsPflichtfelder = response.VertragsPflichtfelder;


    /*   if (store.state.Kunde == 'WasserwerkePaderborn') {

        store.state.AppTitle = "Kundenportal";
        store.state.AppTitleShort = 'Kundenportal'
        document.title = store.state.AppTitle;
        var Logo = await import("@/assets/WWPaderborn/WWP_logo.png");
        store.state.Logo = Logo.default;
        store.state.LogoBreite = "150";
        store.state.LogoHoehe = "24";
        store.state.BezahlItems = [
          "Vorabüberweisung",
          "ohne Kaution",
          "Verrechnungsscheck",
          "SEPA Lastschrift",
          "Mastercard",
          "EC Cash",
        ];


        var AGBpath = await import("@/assets/WWPaderborn/AGB.pdf");
        store.state.AGBpath = AGBpath.default;
        var Datenschutzpath = await import("@/assets/WWPaderborn/Datenschutzerklaerung.pdf")
        store.state.Datenschutzpath = Datenschutzpath.default;
        store.state.CookieBannerEingeschaltet = false
        store.state.DokumentArten = ["Alle","Vollmacht", "Gewerbenachweise/HR-Auszüge","Prüfung: Standrohrrückgabe", "Vertrag", "Fotos", "Prüfung: Werkstattfreigabe", "Rechnungen", "Sonstige Dokumente"]

        import("@/assets/WWPaderborn/WWP_KundenConfig.css"); 
      }*/
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
