import { createStore } from 'vuex'

export default createStore({
  state: {

    //Globale Settings
    Kunde: "",
    Logo: "",
    LogoHoehe: "",
    LogoBreite: "",

    AppTitle: "",
    AppTitleShort:"",

    AGBpath: "",
    Datenschutzpath: "",
    CookieBannerEingeschaltet: false,
    VerwaltungEinschalten: true,
    Bankenmodul: false,
    FelderNichtAnzeigen: [],
    VertragsPflichtfelder: [],
    DokumentArten:[],
    BezahlItems:[],

    CompanyName: "",

    // Gloal Style Setting
    ButtonRound: "",
    // PasswordReset
    TokenReset: "",
    // Main
    Titel: "",
    Page: 0,
    FehlerCode: 0,
    FehlerText: "",
    FehlerTitle: "",

    // Login
    Login: false,
    Rechte: [],
    RechtReservieren: "",
    RechtDokumentLaden: "",
    activeTab: 0,


    //Registrieren
    Benutzerdaten: {

      Login: "",
      Password: "",
      PasswordSame: "",

      Firma: "",
      Land: "",
      Anrede: "",
      Ausweisnummer: "",
      Vorname: "",
      Nachname: "",
      WeitererVorname: "",

      Telefon: "",
      Telefon2: "",
      Mobil: "",
      EMail: "",

      Strasse: "",
      Hausnummer: "",
      HausNrErg: "",
      Plz: "",
      Ort: "",
    },

    Code: "",
    Zufallswert: 0,

    //Vertrag
    Vorgangsnummer: 0,
    Kundennummer: "",
    Anrede: "",
    Firma: "",
    Land: "",
    Vorname: "",
    Nachname: "",
    Telefon: "",
    Telefon2: "",
    Mobil: "",
    EMail: "",
    Land: "",

    Bauform: "",

    Einsatzstelle: "",
    VoraussichtlicheDauer: "",
    Langzeitmiete: false,
    Zahlungsart: "",
    AusgabeName: "",
    AusgabeVorname: "",
    Konzessionsgebiet:"",

    Bank: {
      Kontoinhaber: "",
      Kontonummer: "",
      Name: "",
      Bankleitzahl: 0,
      IBAN: "",
      BIC: "",
    },

    ServicePoint: "",
    Einsatzort: "",
    MitAbwasser: false,
    StueckHydrantenschluessel: [],
    Entnahmezweck: "",
    Bemerkungen: ""
  },
  mutations: {},
  actions: {},
  getters: {},
  modules: {}
})

