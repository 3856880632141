<template>
  <v-card>
    <v-card-title class="HeaderTitle">
      <v-toolbar color="primary">
        <v-toolbar-title> Registrieren </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-icon class="mr-2" @click="cancel()">mdi-close-thick</v-icon>
      </v-toolbar>
    </v-card-title>

    <v-card-text>
      <div v-if="loading == false">
        <Benutzerdaten ref="checkBenutzerdaten" />
      </div>

      <v-row class="ma-2" v-if="loading == true">
        <v-spacer></v-spacer>
        <v-col align-self="auto">
          <v-row>
            <v-progress-circular
              color="primary"
              indeterminate
              :size="100"
              :width="8"
            >
              {{ "Lade.." }}
            </v-progress-circular>
          </v-row>

          <v-row>
            <v-label class="ma-1"> Bitte Warten</v-label>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-row class="ma-2">
      <v-btn @click="sendRegistrierung()">Konto Erstellen</v-btn>
      <v-btn class="ml-2" @click="cancel()">Abbrechen</v-btn>
    </v-row>
  </v-card>

  <v-dialog v-model="showRegistrierenErfolg" persistent>
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar density="compact" color="primary">
          <v-toolbar-title> Erfolgreiche Registrierung </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-col>
          <v-row class="ma-2" align="center" justify="center">
            <v-icon color="green" size="100"> mdi-check-circle </v-icon>
          </v-row>
          <v-row align="center" justify="center">
            Sie haben sich erfolgreich registriert.
          </v-row>
          <v-row align="center" justify="center">
            Bitte überprüfen Sie jetzt Ihr E-Mail Postfach.
          </v-row>
        </v-col>
        <v-row class="ma-2" align="center" justify="center">
          <v-btn @click="closeDialogErfolg()">Ok </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="Fehler">
    <Error />
  </v-dialog>
</template>

<script setup>
import Benutzerdaten from "./Benutzerdaten.vue";

import Error from "@/components/main/Errors.vue";
import dbhelper from "@/plugins/dbHelper";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";


const emit = defineEmits(["closeRegestrieren"]);
const store = useStore();

var Fehler = ref(false);
var loading = ref(false);

var showRegistrierenErfolg = ref(false);
var checkBenutzerdaten = ref(null);

var Page = ref(0);

onMounted(() => {
  store.state.Benutzerdaten.Login = "";
  store.state.Benutzerdaten.Kundennummer = "";
  store.state.Benutzerdaten.Firma = "";
  store.state.Benutzerdaten.Ausweisnummer = "";
  store.state.Benutzerdaten.Anrede = "";
  store.state.Benutzerdaten.Nachname = "";
  store.state.Benutzerdaten.Vorname = "";
  store.state.Benutzerdaten.WeitererVorname = "";
  store.state.Benutzerdaten.Strasse = "";
  store.state.Benutzerdaten.Hausnummer = "";
  store.state.Benutzerdaten.HausNrErg = "";
  store.state.Benutzerdaten.Plz = "";
  store.state.Benutzerdaten.Ort = "";
  store.state.Benutzerdaten.Telefon = "";
  store.state.Benutzerdaten.Telefon2 = "";
  store.state.Benutzerdaten.Mobil = "";
  store.state.Benutzerdaten.EMail = "";
  store.state.Benutzerdaten.EMailAccept = "";
  store.state.Benutzerdaten.Password = "";
  store.state.Benutzerdaten.PasswordSame = "";
  store.state.Benutzerdaten.Land = "";

  store.state.Code = "";
});

async function sendRegistrierung() {
  var BenutzerPflicht = await checkBenutzerdaten.value.checkvalidate();

  if (BenutzerPflicht != false) {
    loading.value = true;
    if (
      store.state.Benutzerdaten.PasswordSame ==
      store.state.Benutzerdaten.Password
    ) {
      var url = "CheckEMailBestaetigungsCode";
      var response2 = await dbhelper.CheckCode(
        url,
        store.state.Zufallswert,
        store.state.Benutzerdaten.EMail,
        store.state.Code
      );

      if (response2.data.erfolgJN == "J") {
        var url = "CreateUser";
        var Benutzer = store.state.Benutzerdaten;
        var response = await dbhelper.sendNewUser(url, Benutzer);
        if (response.data.erfolgJN == "J") {
          showRegistrierenErfolg.value = true;
          store.state.Benutzerdaten = {};
        } else {
          store.state.FehlerCode = 202;
          store.state.FehlerText = response2.data.fehlertext;
          Fehler.value = true;
          loading.value = false;
        }
      } else {
        store.state.FehlerCode = 202;
        store.state.FehlerText = response2.data.fehlertext;
        Fehler.value = true;
        loading.value = false;
      }
    } else {
      Fehler.value = true;
      store.state.FehlerCode = 251;
      loading.value = false;
    }
  }
}

function cancel() {
  emit("closeRegestrieren");
}

function closeDialogErfolg() {
  showRegistrierenErfolg.value = false;
  loading.value = false;
  emit("closeRegestrieren");
}
</script>

<style scoped>
.laoding-balken {
  display: flex;
  margin: auto;
}
.loading-text {
  display: flex;
  margin-left: 33em;
}
</style>