<template>
  <v-form ref="form">
    <v-card class="ma-4" variant="outlined">
      <v-card-title class="HeaderTitle">
        <v-toolbar color="primary" density="compact" flat>
          <v-toolbar-title class="textcolor"> Bankverbindung </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-col v-if="loading == false">
          <v-row class="ma-1" v-if="Vorhanden('Bank') == true">
            <v-combobox
              v-model="$store.state.Bank.Name"
              :items="Banken"
              label="Bankvorauswahl"
              color="primary"
              variant="outlined"
              density="compact"
              @update:modelValue="setDaten()"
              :rules="[rules.required('BankName', $store.state.Bank.Name)]"
            ></v-combobox>
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('Kontoinhaber') == true">
            <v-text-field
              v-model="$store.state.Bank.Kontoinhaber"
              label="Name des Kontoinhabers"
              color="primary"
              density="compact"
              variant="outlined"
              :rules="[
                rules.required('Kontoinhaber', $store.state.Bank.Kontoinhaber),
              ]"
            >
            </v-text-field>
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('IBAN') == true">
            <v-text-field
              v-model="$store.state.Bank.IBAN"
              label="IBAN"
              color="primary"
              variant="outlined"
              density="compact"
              :rules="[
                rules.required('IBAN', $store.state.Bank.IBAN),
                rules.IBANCheck,
              ]"
            >
            </v-text-field>
          </v-row>

          <v-row class="ma-1">
            <v-text-field
              v-if="Vorhanden('BIC') == true"
              class="mr-1"
              v-model="$store.state.Bank.BIC"
              label="BIC"
              color="primary"
              variant="outlined"
              density="compact"
              :rules="[rules.required('BIC', $store.state.Bank.BIC)]"
            >
            </v-text-field>

            <v-text-field
              v-if="Vorhanden('Bankinstitut') == true"
              v-model="$store.state.Bank.Bankinstitut"
              label="Bankinstitut"
              color="primary"
              variant="outlined"
              density="compact"
              :rules="[
                rules.required('Bankinstitut', $store.state.Bank.Bankinstitut),
              ]"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <v-row class="ma-2" v-if="loading == true">
          <loadingscreen />
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script setup>
import Errors from "@/components/main/Errors.vue";
import loadingscreen from "@/components/main/loadingscreen.vue";
import dbhelper from "@/plugins/dbHelper.js";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

defineExpose({
  checkvalidate,
  resetFelder,
});

const Bankenurl = "GETBanken";

const store = useStore();

var form = ref(null);
var loading = ref(false);
var BankenItems = ref([]);
var Banken = ref([]);

var rules = ref({
  required(Feld, value) {
    var istPflichtfeld = false;
    store.state.VertragsPflichtfelder.forEach((element) => {
      if (element == Feld) istPflichtfeld = true;
    });
    if (istPflichtfeld == true) return !!value || "Pflichtfeld";
    else return true;
  },
  IBANCheck(value) {
    var test = DoIBANCheck(value);
    if (test == true) return true;
    else return "Keine gültige IBAN";
  },
});

onMounted(async () => {
  loading.value = true;
  var response = await dbhelper.dbload(Bankenurl);
  var BankenDB = JSON.parse(response.DataArray);
  BankenDB.forEach((element) => {
    Banken.value.push(element.Name);
  });
  BankenItems.value = BankenDB;
  loading.value = false;
});

function setDaten() {
  BankenItems.value.forEach((element) => {
    if (element.Name == store.state.Bank.Name) {
      store.state.Bank.Bankinstitut = element.Name;
      store.state.Bank.BIC = element.BIC;
    }
  });
}

function Vorhanden(Feld) {
  var FeldNichtvorhandewn = false;
  store.state.FelderNichtAnzeigen.forEach((element) => {
    if (element == Feld) FeldNichtvorhandewn = true;
  });
  if (FeldNichtvorhandewn == true) return false;
  else return true;
}

async function checkvalidate() {
  let test = await form.value.validate();
  if (test.valid == true) return true;

  return false;
}

function resetFelder() {
  form.value.reset();
}

function DoIBANCheck(IBAN) {
  return true;

  var LaenderCode1 = IBAN.substring(0, 1);
  var LaenderCode2 = IBAN.substring(1, 2);
  var Kontrollziffern = IBAN.substring(2, 4);
  var Kontonummer = IBAN.substring(4, 22);
  var Codieren = {
    A: 10,
    B: 11,
    C: 12,
    D: 13,
    E: 14,
    F: 15,
    G: 16,
    H: 17,
    I: 18,
    J: 19,
    K: 20,
    L: 21,
    M: 22,
    N: 23,
    O: 24,
    P: 25,
    Q: 26,
    R: 27,
    S: 28,
    T: 29,
    U: 30,
    V: 31,
    W: 32,
    X: 33,
    Y: 34,
    Z: 35,
  };
  var LaenderCode1Codiert1 = Codieren[LaenderCode1];
  var LaenderCode1Codiert2 = Codieren[LaenderCode2];
  var BBAN =
    Kontonummer + LaenderCode1Codiert1 + LaenderCode1Codiert2 + Kontrollziffern;
  if (BBAN % 97 == 1) return true;
  else return false;
}
</script>

<style>
.textcolor {
  color: white;
}
</style>